import { useState } from "react"
import { SelectChangeEvent } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import {
    defaultOutputChannelsActual,
    defaultOutputChannelsIndex,
    outputChannelCountPossibilities,
    outputChannelCountPossibilitiesReverseMap,
} from "@/types/AppMessage"
import { StandardSelectProps } from "@/types/StandardSelectProps"
import { SelectorExtraProps } from "@/types/DeviceSelector"
import { Agent, LocalDevice } from "@/services/BonzaService"
import { fNOP, sendSaveSettings } from "@/types/Device"
import { checkOKToStart } from "../SelectMenu"

function getValidOutputChannelIndex(outChannelsActual: string): number {
    // var TEST_DELME : Boolean = true;
    // if (TEST_DELME) {
    //     const debfail1 = outputChannelCountPossibilitiesReverseMap[-1];
    //     const debfail2 = outputChannelCountPossibilitiesReverseMap[NaN];
    //     // both are undefined
    //     fNOP();
    // }
    const tmp =
        outputChannelCountPossibilitiesReverseMap[Number(outChannelsActual)]
    return tmp ?? defaultOutputChannelsIndex
}

export default function OutputChannelsSelector(props: SelectorExtraProps) {
    const derivedIndex: number = getValidOutputChannelIndex(
        props.device.getSavedSettings().outChannelsActual
    )
    const [value, setValue] = useState(props.value ?? derivedIndex)

    const handleChange = (event: SelectChangeEvent) => {
        const index: number = JSON.parse(event.target.value)
        setValue(index)

        //props.device.deviceDataStore.outChans = outputChannelCountPossibilities[index];
        //props.device.deviceDataStore.outChansSelectIndex = index;

        const tmp = `${outputChannelCountPossibilities[index]}`
        const settings = props.device.getSavedSettings()
        if (settings.outChannelsActual != tmp) {
            settings.outChannelsActual = tmp
            props.device.saveSavedSettings(settings)
            sendSaveSettings(Agent, settings)
        }

        if (checkOKToStart(props)) {
            const inhibRequestNewSettings: boolean = true
            props.device.sequence_Start(inhibRequestNewSettings)
        }

        if (props.handleChange) props.handleChange(event)
    }

    function calcDisabledState(index: number): boolean {
        const dev = LocalDevice
        const outcard = dev.activeOutputSoundCard
        if (!outcard) {
            return true
        }
        const maxchans = outcard?.outputChannels
        const thisnc = outputChannelCountPossibilities[index]
        if (thisnc > maxchans) {
            return true
        }
        return false
    }

    function LimitValue(inval: number | string | readonly string[]): string {
        const dev = LocalDevice
        const outcard = dev.activeOutputSoundCard
        if (!outcard) {
            return "0"
        }
        const maxchans = outcard.outputChannels
        let valn = inval instanceof Array ? inval[0] : inval
        valn = Number(valn)
        while (valn > 0) {
            const thisnc = outputChannelCountPossibilities[valn]
            if (thisnc > maxchans) {
                --valn
                setValue(valn)
            } else {
                break
            }
        }
        return valn.toString()
    }

    const id = props.id ?? "bonza-output-channel-select"
    return (
        <FormControl>
            <Select
                value={LimitValue(value)}
                onChange={handleChange}
                {...StandardSelectProps}
                id={id}
                labelId={`${id}-label`}
                disabled={props.disabled}
                name={props.name ?? "OutputChannelsSelector"}
            >
                {outputChannelCountPossibilities.map((count, index) => (
                    <MenuItem
                        key={index}
                        value={index}
                        disabled={calcDisabledState(index)}
                    >
                        {count}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
