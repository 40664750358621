import { SoundCardSelectorProps } from "@/types/DeviceSelector"
import { useEffect, useState } from "react"
import { checkOKToStart } from "@/components/SelectMenu"
import {
    DeviceChangeEventType,
    DeviceChangeListener,
    IDevice,
    sendSaveSettings,
} from "@/types/Device"
import { Agent } from "@/services/BonzaService"
import SelectInput from "../formInputs/SelectInput"

export default function InputSoundCardSelector(props: SoundCardSelectorProps) {

    const [soundCards, setSoundCards] = useState(props.device.inputSoundCards)
    const [selectedSoundCard, setSelectedSoundCard] = useState(
        props.device.activeInputSoundCard
    )

    const handleChange = (value: string | undefined) => {
        const soundCard = soundCards.find((c) => value == `${c.name}`)

        props.device.activeInputSoundCard = soundCard ?? null
        const tmp: string | undefined = soundCard ? soundCard.name : undefined
        const workingDataRef = props.device.getSavedSettings()
        if (workingDataRef.audioInCardName != tmp) {
            workingDataRef.audioInCardName = tmp
            props.device.saveSavedSettings(workingDataRef)
            sendSaveSettings(Agent, workingDataRef)
        }
        if (checkOKToStart(props)) {
            const inhibRequestNewSettings: boolean = true
            props.device.sequence_Start(inhibRequestNewSettings)
        }
    }

    useEffect(() => {
        const deviceChangeListener: DeviceChangeListener = {
            handleDeviceChange(
                device: IDevice,
                eventType: DeviceChangeEventType
            ) {
                if (eventType == DeviceChangeEventType.SoundCards) {
                    setSoundCards(props.device.inputSoundCards)
                    setSelectedSoundCard(props.device.activeInputSoundCard)
                }
            },
        }
        props.device.addDeviceChangeListener(deviceChangeListener)
        //logger.info(`ICS-UE ran`);
        return () => {
            props.device.removeDeviceChangeListener(deviceChangeListener)
        }
        //}, [props.device.soundCards])
    }, [])

    return (
        <SelectInput
            disabled={props.disabled}
            defaultValue={`${selectedSoundCard ? selectedSoundCard.name : "NONE"}`}
            onChange={(event) => handleChange(event.target.value)}
        >
            <option key={"None"} value={"NONE"}>
                None
            </option>
            {soundCards.map((soundCard, i) => {
                return (
                    <option
                        key={i}
                        value={soundCard.name}
                        selected={
                            selectedSoundCard != null &&
                            selectedSoundCard.name == soundCard.name
                        }
                    >
                        {soundCard.index} {soundCard.name} (
                        {soundCard.inputChannels} in,{" "}
                        {soundCard.outputChannels} out)
                    </option>
                )
            })}
        </SelectInput>
    )
}
