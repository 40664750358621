import {GenericSelectorProps, SelectorProps} from "@/types/DeviceSelector";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {StandardSelectProps} from "@/types/StandardSelectProps";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {useState} from "react";
import InputLabel from "@mui/material/InputLabel";

export default function GenericSelector(props: GenericSelectorProps) {
    const options = [];
    const [value, setValue] = useState<string>(props.value ?? '')
    for (let i in props.options) {
        options.push(<MenuItem value={i} key={i}>{props.options[i]}</MenuItem>)
    }

    const handleChange = (e: SelectChangeEvent | { target: { name: string; value: string | number | readonly string[]; }; }) => {
        setValue(e.target.value as string)
        if(props.handleChange) props.handleChange(e);
    }

    const labelId = props.label ? `${Math.floor(Math.random() * 1024)}` : undefined

    return (
        <FormControl fullWidth>
            {labelId && <InputLabel id={labelId}>{props.label}</InputLabel>}
            <Select {...StandardSelectProps}
                labelId={labelId}
                label={props.label}
                title={props.title}
                value={value}
                disabled={props.disabled}
                onChange={handleChange}
            >
                {options}
            </Select>
        </FormControl>
    )
}
