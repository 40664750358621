import { ChangeEvent, ComponentProps, useState } from "react"
import Checkbox from "@/components/Checkbox"
import FormControl from "@mui/material/FormControl"
import { SelectChangeEvent } from "@mui/material"
import { Agent, LocalDevice } from "@/services/BonzaService"
import {
    localDirectOnOffMessageV240124,
    ozoneMessages,
    setOzoneMessage,
    settingsDataV240124,
} from "@/types/AppMessage"
import { sendSaveSettings } from "@/types/Device"

export default function DirectAudioCheckbox(props: ComponentProps<"input">) {
    const [value, setValue] = useState(
        LocalDevice.getSavedSettings().directOnOff === "1" ? true : false
    )

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked
        const settings = LocalDevice.getSavedSettings()
        const tmp: string = checked ? "1" : "0"
        if (settings.directOnOff != tmp) {
            settings.directOnOff = tmp
            LocalDevice.saveSavedSettings(settings)
            Agent.send(new localDirectOnOffMessageV240124(checked))
            sendSaveSettings(Agent, settings)
        }
        setValue(checked)

        if (props.onChange) props.onChange(event)
    }

    return (
        <FormControl>
            <Checkbox
                defaultChecked={props.defaultChecked}
                className="mt-2"
                onChange={handleChange}
                checked={value}
            />
        </FormControl>
    )
}
