import { SelectorProps } from "@/types/DeviceSelector"
import { useState } from "react"
import { Agent, LocalDevice } from "@/services/BonzaService"
import { setColorOrBWMessage } from "@/types/AppMessage"
import { sendSaveSettings } from "@/types/Device"
import SelectInput from "../formInputs/SelectInput"

const ColorOpts = ["BW", "Colour"]
export const defaultBwIndex: number = 1
export default function VideoColorSelector(props: SelectorProps) {
    const [bwIndex, setBWIndex] = useState<number>(() =>
        parseInt(
            LocalDevice.getSavedSettings()?.videoColorIndex ??
                defaultBwIndex.toString()
        )
    )

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const bwIndex = parseInt(event.target.value)
        const settings = LocalDevice.getSavedSettings()
        if (settings.videoColorIndex != `${bwIndex}`) {
            settings.videoColorIndex = `${bwIndex}`
            LocalDevice.saveSavedSettings(settings)
            Agent.send(new setColorOrBWMessage(bwIndex))
            sendSaveSettings(Agent, settings)
        }

        setBWIndex(bwIndex)

        if (props.handleChange)
            props.handleChange({
                target: {
                    name: props.name ?? "VideoColourSelector",
                    value: bwIndex,
                },
            })
    }

    const values = []
    for (const i in ColorOpts) {
        values.push(
            <option value={i} key={i} selected={i == ColorOpts[bwIndex]}>
                {ColorOpts[i]}
            </option>
        )
    }

    return (
        <SelectInput
            value={`${bwIndex}`}
            disabled={props.disabled}
            onChange={(e) => handleChange(e)}
        >
            {values}
        </SelectInput>
    )
}
