export default function Plus(props: React.ComponentProps<"svg">) {
    return (
        <svg
            viewBox="0 0 17 18"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="7.18359"
                y="0.0361328"
                width="2.61538"
                height="17"
                rx="1.30769"
            />
            <rect
                x="16.9902"
                y="7.22852"
                width="2.61538"
                height="17"
                rx="1.30769"
                transform="rotate(90 16.9902 7.22852)"
            />
        </svg>
    )
}
