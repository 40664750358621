import { UseFormRegisterReturn } from "react-hook-form"

type CheckboxProps = Omit<React.ComponentProps<"input">, "type"> & {
    register?: UseFormRegisterReturn<string>
}

export default function Checkbox({
    className,
    register,
    ...props
}: CheckboxProps) {
    return (
        <input
            {...props}
            type="checkbox"
            className={`
                rounded border-bonza-dark-semi text-bonza-primary shadow-sm

                focus:ring-bonza-primary

                ${className}
            `}
            {...register}
        />
    )
}
