export default function Alert(props: React.ComponentProps<"svg">) {
    return (
        <svg
            viewBox="0 0 20 21"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M9.98892 18.5369C7.37616 18.5373 4.76357 18.5376 2.15081 18.5364C1.97694 18.5362 1.79783 18.5488 1.63025 18.513C0.917994 18.3608 0.734339 17.7614 1.16438 17.261C2.52196 15.6811 3.2136 13.8248 3.37803 11.7461C3.51276 10.0397 3.69362 8.33526 3.9124 6.63763C4.17503 4.5996 5.32362 3.23677 7.27061 2.60036C9.07222 2.0113 10.907 2.0085 12.706 2.59808C14.7447 3.26613 15.8613 4.72314 16.092 6.85431C16.2919 8.701 16.4819 10.5486 16.6784 12.3954C16.87 14.1953 17.5981 15.7667 18.7212 17.1673C18.9321 17.4305 19.1892 17.6797 18.9976 18.0538C18.8014 18.4368 18.4451 18.5369 18.0448 18.5369C15.3595 18.5364 12.6742 18.5366 9.98892 18.5369Z" />
            <path d="M11.8127 19.2178C11.755 20.1229 10.9582 20.838 10.0168 20.8581C9.07742 20.8782 8.24774 20.1628 8.15723 19.2178H11.8127Z" />
            <path d="M11.3291 1.57918H8.6401C8.53159 1.02367 8.75508 0.520581 9.26097 0.206218C9.75182 -0.0987094 10.3788 -0.0646344 10.8475 0.292366C11.2798 0.621757 11.4603 1.12554 11.3291 1.57918Z" />
        </svg>
    )
}
