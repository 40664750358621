import {
    DeviceChangeEventType,
    DeviceChangeListener,
    IDevice,
    VideoDevice,
} from "@/types/Device"
import { ComponentProps, useEffect, useState } from "react"
import SelectInput from "../formInputs/SelectInput"

interface VideoDeviceSelectorProps extends ComponentProps<"select"> {
    title?: string
    device: IDevice
    handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}
//this.logger.log(`Starting, agent state is ${ReadyState[Agent.readyState]}`);
export default function VideoDeviceSelector(props: VideoDeviceSelectorProps) {
    const [videoDevices, setVideoDevices] = useState(props.device.videoDevices)
    const [selectedVideoDevice, setSelectedVideoDevice] = useState(
        props.device.activeVideoDevice
    )

    const deviceChangeListener: DeviceChangeListener = {
        handleDeviceChange(device: IDevice, eventType: DeviceChangeEventType) {
            if (eventType == DeviceChangeEventType.VideoDevices) {
                setVideoDevices(props.device.videoDevices)
            }
        },
    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const videoDevice = videoDevices.find(
            (c) => event.target.value == `${c.index}`
        )
        props.device.activeVideoDevice = videoDevice ?? null

        const settings = props.device.getSavedSettings()
        // settings.videoCardIndex = `${event.target.value}`;
        props.device.saveSavedSettings(settings)

        setSelectedVideoDevice(videoDevice ?? null)
        if (props.onChange) {
            props.onChange(event)
        }
    }

    useEffect(() => {
        props.device.addDeviceChangeListener(deviceChangeListener)

        return () => {
            props.device.removeDeviceChangeListener(deviceChangeListener)
        }
    }, [])

    return (
        <SelectInput
            value={`${selectedVideoDevice ? selectedVideoDevice.index : -1}`}
            disabled={props.disabled}
            onChange={(event) => handleChange(event)}
        >
            <option key={"None"} value={-1}>
                None
            </option>
            {videoDevices.map((videoDevice: VideoDevice, i) => {
                return (
                    <option key={i} value={videoDevice.index}>
                        {videoDevice.index} {videoDevice.name}
                    </option>
                )
            })}
        </SelectInput>
    )
}
