import { LocalRemoteManager } from "@/services/BonzaService"
import { fNOP } from "@/types/Device"
import { PlayerViewRemote } from "@/types/PlayerView"

export type PlayerAction = {
    action: "SET" | "CREATE" | "EDIT" | "IMG" | "DELETE"
    playerArr?: PlayerViewRemote[]
    player?: PlayerViewRemote
    partialPlayer?: Partial<PlayerViewRemote>
    imgData?: {
        IP: string
        port: string
        img: string
    }
}

export const playerReducer = (
    players: PlayerViewRemote[],
    action: PlayerAction
) => {
    switch (action.action) {
        case "SET":
            return action.playerArr !== undefined ? [...action.playerArr] : []
        case "CREATE":
            if (action.player) players.push(action.player)
            return [...players]
        case "EDIT":
            if (action.partialPlayer !== undefined) {
                const playerIndex = players.findIndex(
                    (p) =>
                        action.partialPlayer &&
                        p.name === action.partialPlayer.name
                )
                if (playerIndex !== -1) {
                    players[playerIndex] = {
                        ...players[playerIndex],
                        ...action.partialPlayer,
                    }
                    return players.slice()
                }
            }
            return [...players]
        case "IMG": {
            if (!action.imgData) {
                return players
            }
            const index = players.findIndex(
                (p) =>
                    p.ip === action.imgData?.IP &&
                    p.port === action.imgData?.port
            )
            if (index > -1) {
                players[index] = {
                    ...players[index],
                    img: action.imgData.img,
                }
                return [...players]
            }
            return players
        }
        case "DELETE":
            if (
                action.partialPlayer !== undefined &&
                action.partialPlayer.name !== undefined
            ) {
                // const deleteIndex = players.findIndex(p => action.player && p.name === action.player.name)
                // if (deleteIndex !== -1) {
                //     return players.splice(deleteIndex, 1);
                // }
                const oldLen = players.length
                if (oldLen == 0) {
                    return players
                }
                const name: string = action.partialPlayer.name
                players = players.filter((p) => !(p.name === name))
                const newLen = players.length
                if (oldLen != newLen + 1 && players && players.length) {
                    LocalRemoteManager.logger
                        .error(`PlayerReducer:60 delete player failed for ${action.partialPlayer.name} players are
                    ${[players.map((p) => p.name)]}`)
                    fNOP()
                }
                return players
            } else {
                fNOP()
            }
            return [...players]
    }
}
