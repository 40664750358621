import { SelectorExtraProps, SelectorProps } from "@/types/DeviceSelector"
import { useState } from "react"
import { SelectChangeEvent } from "@mui/material"
import { Agent, LocalDevice } from "@/services/BonzaService"
import {
    diffuseIRDepthMessageV240124,
    ozoneMessages,
    setOzoneMessage,
} from "@/types/AppMessage"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { StandardSelectProps } from "@/types/StandardSelectProps"
import { sendSaveSettings } from "@/types/Device"

const ZoneNamesORG = ["A", "B", "C"]
const ZoneNames = ["A", "B", "C", "W"]
export default function TwoChannelZoneSelector(props: SelectorProps) {
    const [zoneIndex, setZoneIndex] = useState(
        parseInt(LocalDevice.getSavedSettings().oZoneIndex ?? "0")
    )

    const handleChange = (event: SelectChangeEvent) => {
        const zoneIndex = parseInt(event.target.value)
        const settings = LocalDevice.getSavedSettings()
        if (settings.oZoneIndex != `${zoneIndex}`) {
            settings.oZoneIndex = `${zoneIndex}`
            LocalDevice.saveSavedSettings(settings)
            Agent.send(new setOzoneMessage(ozoneMessages[zoneIndex]))
            sendSaveSettings(Agent, settings)
        }
        setZoneIndex(zoneIndex)

        if (props.handleChange) props.handleChange(event)
    }

    const values = []
    for (const i in ZoneNames) {
        values.push(
            <MenuItem value={i} key={i}>
                Zone {ZoneNames[i]}
            </MenuItem>
        )
    }

    return (
        <FormControl fullWidth>
            <Select
                {...StandardSelectProps}
                value={`${zoneIndex}`}
                disabled={props.disabled}
                onChange={(e) => handleChange(e)}
            >
                {values}
            </Select>
        </FormControl>
    )
}
