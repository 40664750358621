export default function Profile(props: React.ComponentProps<"svg">) {
    return (
        <svg
            viewBox="0 0 20 18"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M5.73759 7.50647C4.8468 4.86348 5.38221 2.80103 7.32252 1.4675C9.05872 0.274293 11.37 0.377307 13.0185 1.72552C14.5986 3.01789 15.2863 5.33764 14.2309 7.54137C14.3211 7.59324 14.4135 7.64607 14.5056 7.6995C17.6759 9.53848 19.3536 12.2774 19.4757 15.9488C19.4952 16.5347 19.2039 16.9697 18.7125 17.1524C18.2391 17.3283 17.672 17.2067 17.3942 16.7863C17.2339 16.5438 17.1361 16.2204 17.121 15.9279C16.9812 13.2032 15.7444 11.1576 13.3828 9.79024C13.3742 9.7853 13.3646 9.78193 13.3562 9.77664C12.8887 9.48096 12.4774 9.4138 11.9063 9.69011C10.5062 10.3674 9.07243 10.2571 7.70088 9.51898C7.53155 9.42776 7.39917 9.41272 7.22901 9.49215C4.52729 10.7549 3.07847 12.8707 2.88785 15.8461C2.87762 16.0053 2.88652 16.1693 2.85006 16.3225C2.70829 16.9176 2.14978 17.2924 1.53951 17.2168C0.95019 17.1438 0.514666 16.6395 0.523572 16.0006C0.56497 13.034 1.73748 10.5969 4.01342 8.70341C4.54703 8.25959 5.15609 7.90638 5.73759 7.50647ZM9.99692 7.7415C11.3073 7.74259 12.3671 6.68814 12.3683 5.38204C12.3695 4.08017 11.304 3.01187 10.0021 3.00959C8.70454 3.00742 7.63168 4.07788 7.62963 5.37675C7.62747 6.67971 8.68938 7.74042 9.99692 7.7415Z" />
        </svg>
    )
}
