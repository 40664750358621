import { Button, IconButton, IconButtonProps, useTheme } from "@mui/material"

interface LabelledIconButtonProps extends IconButtonProps {
    active?: string
    width?: string
    height?: string
    label?: string
    labelstyle?: any
    flex?: "col" | "row"
}

export default function LabelledIconButton(props: LabelledIconButtonProps) {
    const active = "true" == props.active
    const labelStyle = props.labelstyle ?? {}
    if (!labelStyle.color) {
        const theme = useTheme()
        labelStyle.color = theme.palette.secondary.main
    }
    if (!labelStyle.textTransform) {
        labelStyle.textTransform = "none"
    }

    if (props.flex == "row") labelStyle.justifyContent = "left"

    return (
        <div
            className={`
                flex

                flex-${props.flex ?? "col"}

                items-center
            `}
            style={{
                width: props.width ?? "100px",
                height: props.height ?? "80px",
            }}
        >
            <IconButton {...props} color={active ? "primary" : "secondary"} />
            <Button
                style={{ ...labelStyle }}
                className={`
                    ${active ? "brightness-200" : ""}
                `}
                onClick={props.onClick ?? undefined}
            >
                {props.label}
            </Button>
        </div>
    )
}
