import "./bootstrap"
import "../css/app.css"
import "react-datepicker/dist/react-datepicker.css"

import { createRoot } from "react-dom/client"
import { createInertiaApp } from "@inertiajs/react"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { BonzaContextProvider } from "./context/BonzaContext"
import AuthenticatedLayout from "./layouts/AuthenticatedLayout"

const appName = import.meta.env.VITE_APP_NAME || "Bonza"

function setupPageLayout(module, name) {
    if (!name.startsWith("Welcome")) {
        module.default.layout = (page) => (
            <AuthenticatedLayout>{page}</AuthenticatedLayout>
        )
    }
}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        console.log("page name:", name)
        const page = resolvePageComponent(
            `./pages/${name}.tsx`,
            import.meta.glob("./pages/**/*.tsx")
        )
        page.then((module) => setupPageLayout(module, name))
        return page
    },
    setup({ el, App, props }) {
        const root = createRoot(el)
        const queryClient = new QueryClient()

        root.render(
            <QueryClientProvider client={queryClient}>
                <BonzaContextProvider>
                    <App {...props} />
                </BonzaContextProvider>
            </QueryClientProvider>
        )
    },
    progress: {
        color: "#4B5563",
    },
})
