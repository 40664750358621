export default function Groups(props: React.ComponentProps<"svg">) {
    return (
        <svg
            viewBox="0 0 21 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M9.12193 5.702C8.38704 3.52156 8.82875 1.82006 10.4295 0.719909C11.8618 -0.264476 13.7687 -0.17949 15.1286 0.932771C16.4322 1.99897 16.9995 3.91273 16.1288 5.73079C16.2033 5.77358 16.2795 5.81717 16.3555 5.86125C18.9709 7.37839 20.355 9.63795 20.4557 12.6669C20.4718 13.1502 20.2315 13.5091 19.8261 13.6598C19.4356 13.8049 18.9677 13.7046 18.7385 13.3578C18.6063 13.1577 18.5256 12.8909 18.5132 12.6496C18.3978 10.4017 17.3775 8.71413 15.4291 7.58608C15.4221 7.58201 15.4142 7.57923 15.4072 7.57486C15.0215 7.33093 14.6822 7.27553 14.2111 7.50348C13.056 8.06224 11.8732 7.9712 10.7416 7.3623C10.6019 7.28705 10.4927 7.27463 10.3523 7.34016C8.12345 8.38193 6.92819 10.1274 6.77093 12.5821C6.76249 12.7134 6.76983 12.8487 6.73975 12.9751C6.6228 13.4661 6.16202 13.7753 5.65856 13.7129C5.17238 13.6527 4.81307 13.2366 4.82042 12.7096C4.85458 10.2621 5.82188 8.25157 7.69952 6.68946C8.13973 6.32331 8.6422 6.03192 9.12193 5.702ZM12.6358 5.8959C13.7169 5.89679 14.5912 5.02688 14.5922 3.94937C14.5932 2.87533 13.714 1.994 12.6401 1.99211C11.5696 1.99033 10.6845 2.87344 10.6829 3.945C10.6811 5.01993 11.5571 5.89501 12.6358 5.8959Z" />
            <path d="M9.31744 7.33284C9.43499 7.29472 9.56545 7.28002 9.69809 7.2824C9.27207 6.46233 8.60142 5.78929 7.6906 5.26101C7.64334 5.23361 7.59579 5.20641 7.54942 5.1797C8.0921 4.04639 7.73845 2.85342 6.92583 2.18882C6.07815 1.49543 4.88944 1.44251 3.9966 2.05618C2.99871 2.74192 2.7234 3.80255 3.18149 5.16183C2.88245 5.36745 2.56921 5.54913 2.2948 5.77738C1.12436 6.75114 0.521315 8.00438 0.500069 9.53006C0.495502 9.85868 0.719483 10.118 1.02249 10.1555C1.33632 10.1944 1.62365 10.0017 1.69652 9.69566C1.71529 9.61683 1.71072 9.53244 1.71598 9.45063C1.81397 7.92039 2.55909 6.83236 3.94855 6.18295C4.03601 6.14215 4.10402 6.14989 4.19119 6.19675C4.89649 6.57631 5.63386 6.6331 6.35396 6.28471C6.64764 6.14264 6.85911 6.17719 7.09947 6.32929C7.10384 6.33197 7.1088 6.33366 7.11327 6.33624C7.78373 6.72444 8.27696 7.21956 8.59725 7.82042C8.77596 7.6008 9.02098 7.42875 9.31744 7.33284ZM5.37196 5.28266C4.69952 5.28206 4.15346 4.7366 4.15446 4.06655C4.15555 3.39857 4.70736 2.84805 5.37464 2.84914C6.0441 2.85034 6.59204 3.39977 6.59144 4.06923C6.59085 4.74097 6.04589 5.28325 5.37196 5.28266Z" />
        </svg>
    )
}
