// BasicSelect from https://github.com/mui/material-ui/blob/v5.15.3/docs/data/material/components/selects/BasicSelect.tsx
// https://mui.com/material-ui/react-select/#basic-select

import * as React from "react"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { ComponentProps } from "react"
import { WebSocketService } from "@/types/WebSocket"
import { IAgentService } from "@/services/AgentService"
import {
    diffuseIRDepthMessageV240124,
    inputChannelCountPossibilities,
    latencies,
    latencyStrings,
    outputChannelCountPossibilities,
    ozoneMessages,
    setOzoneMessage,
    spatialLocationMessage,
    spatialLocations,
} from "@/types/AppMessage"
import { IDevice, fNOP } from "@/types/Device"
import { SoundCardSelectorProps } from "../types/DeviceSelector"
import { StandardSelectProps } from "@/types/StandardSelectProps"

/// WIP rdy to inherit IF do it that way?
export enum SelectTypes {
    Generic = 0, //  no
    VideoInterfaceType, //  no
    //AudioInterfaceInputType,
    //AudioInterfaceOutputType,
    // above 2 are handled by SoundCardSelector
    CaptureChansType, //  yes
    OutputChansType, //  yes
    // above 2 depend on props.device.activeInputSoundCard and props.device.activeOutputSoundCard
    LatencyType, //  yes
    LocationType, //  yes
    DiffuseIRDepthType, //  yes
    TwoChanZoneSelectType, //  yes
}

/// WIP - just reminders of strings that would need to be populated...
// see types/AppMessage.ts
// need ONE place to define both message content AND GUI show-string for that msg...
// some are fixed (compiled-in,
// others (*) can only be populated when relev msg(s)received wrt them...
const SelectTypeItems: string[] = [
    "no video", // else * videoDevices as message(s)
    "no audio input", // else * audioInDevices as message(s)
    "no audio output", // else * audioOutDevices as message(s)
    "lowest delay (64)", // else "low delay (128)","moderate delay (256),"fair delay (512)"
    "1 (mono)", // else "2 (mono mix)","2 (stereo)"," "4","8", "16"?
    "none", // else <locations>
    "Diffuse IR Depth 1", // else 2-5,mute locals
    "2 (one zone)", // 2,4 8,16
    "zone A", // zone B, zone C
]

interface SelectProps extends ComponentProps<"select"> {
    type: SelectTypes
    agent: IAgentService & WebSocketService
    device: IDevice
    handleChange?(event: SelectChangeEvent): void
}

export function checkOKToStart(
    props: SelectProps | SoundCardSelectorProps
): boolean {
    let ok = false

    if (props.device != null && props.device != undefined) {
        const dev = props.device
        if (
            dev.activeInputSoundCard != null &&
            dev.activeOutputSoundCard != null
        ) {
            ok = true
        } else {
            fNOP()
        }
    } else {
        // no props.device!!!
        fNOP()
    }
    // TODO !!! - also check when select in chans theyre >= soundcard's in chans
    // TODO !!! - also check when select out chans theyre >= soundcard's out chans
    // need to fix this in the actual available selections rather than this way
    return ok
}

export default function BasicSelect(props: SelectProps) {
    let initsel = 0
    if (props.type == SelectTypes.OutputChansType) {
        initsel = 1
    }
    if (props.type == SelectTypes.LatencyType) {
        initsel = 2
    }
    //  const [myVal, setMyVal] = React.useState(initsel);
    const [myVal, setMyVal] = React.useState(props.value ?? initsel)
    let key = 0

    //const [inSoundCard, setInSoundCard] = React.useState(props.device?.activeInputSoundCard);
    //const [outSoundCard, setOutSoundCard] = React.useState(props.device?.activeOutputSoundCard);
    // now unused - see InputSoundCardSelector and OutputSoundCardSelector

    const handleChange = (event: SelectChangeEvent) => {
        const newIndex: number = JSON.parse(event.target.value)
        setMyVal(newIndex)
        if (props.type == SelectTypes.DiffuseIRDepthType) {
            if (props.device) {
                var currSettingsRef = props.device.getSavedSettings()
                currSettingsRef.diffuseIRLevelIndex = newIndex.toString()
                props.device.saveSavedSettings(currSettingsRef)
            }
            props.agent.send(new diffuseIRDepthMessageV240124(newIndex))
        } else if (props.type == SelectTypes.TwoChanZoneSelectType) {
            if (props.device) {
                var currSettingsRef = props.device.getSavedSettings()
                currSettingsRef.oZoneIndex = newIndex.toString()
                props.device.saveSavedSettings(currSettingsRef)
            }
            const messages = ozoneMessages
            props.agent.send(new setOzoneMessage(messages[newIndex]))
        } else if (props.type == SelectTypes.LocationType) {
            if (props.device) {
                var currSettingsRef = props.device.getSavedSettings()
                currSettingsRef.spatialLocationIndex = newIndex.toString()
                props.device.saveSavedSettings(currSettingsRef)
            }
            const messages = spatialLocations
            props.agent.send(new spatialLocationMessage(messages[newIndex]))
        } else if (props.type == SelectTypes.LatencyType) {
            //const newLatency = latencies[newIndex];
            //alert(`Latency was changed to index ${newIndex}, (${newLatency})`);
            if (props.device) {
                var currSettingsRef = props.device.getSavedSettings()
                currSettingsRef.frameSizeIndex = newIndex.toString()
                props.device.saveSavedSettings(currSettingsRef)
                if (checkOKToStart(props)) {
                    const inhibRequestNewSettings: boolean = true
                    props.device.sequence_Start(inhibRequestNewSettings)
                }
            }
        } else if (props.type == SelectTypes.CaptureChansType) {
            /// !!! TODO (for both): grey out illegal vals dep on selected sc-in
            if (props.device) {
                const newVal = inputChannelCountPossibilities[newIndex]
                //props.device.deviceDataStore.inChans = newVal;
                //props.device.deviceDataStore.inChansSelectIndex = newIndex;
                var currSettingsRef = props.device.getSavedSettings()
                currSettingsRef.inChannelsIndex = newIndex.toString()
                props.device.saveSavedSettings(currSettingsRef)
                if (checkOKToStart(props)) {
                    const inhibRequestNewSettings: boolean = true
                    props.device.sequence_Start(inhibRequestNewSettings)
                }
            }
        } else if (props.type == SelectTypes.OutputChansType) {
            if (props.device) {
                const newVal = outputChannelCountPossibilities[newIndex]
                //props.device.deviceDataStore.outChans = newVal;
                //props.device.deviceDataStore.outChansSelectIndex = newIndex;
                var currSettingsRef = props.device.getSavedSettings()
                currSettingsRef.outChannelsActual = newVal.toString()
                props.device.saveSavedSettings(currSettingsRef)
                if (checkOKToStart(props)) {
                    const inhibRequestNewSettings: boolean = true
                    props.device.sequence_Start(inhibRequestNewSettings)
                }
            }
        }

        if (props.handleChange) {
            props.handleChange(event)
        }
    } // eo handleChange

    if (props.type == SelectTypes.DiffuseIRDepthType) {
        return (
            <div
                className={`
                    ${props.className}
                `}
            >
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <Select
                            {...StandardSelectProps}
                            labelId="basic-select-label"
                            id="basic-select"
                            value={myVal.toString()}
                            label="Diffuse IR Depth"
                            onChange={handleChange}
                            sx={{ color: "#FFFFFF" }}
                        >
                            <MenuItem value={0} key={key++}>
                                Diffuse IR Off
                            </MenuItem>
                            <MenuItem value={1} key={key++}>
                                Diffuse IR Depth 1
                            </MenuItem>
                            <MenuItem value={2} key={key++}>
                                Diffuse IR Depth 2
                            </MenuItem>
                            <MenuItem value={3} key={key++}>
                                Diffuse IR Depth 3
                            </MenuItem>
                            <MenuItem value={4} key={key++}>
                                Diffuse IR Depth 4
                            </MenuItem>
                            <MenuItem value={5} key={key++}>
                                mute locals
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
        )
    } else if (props.type == SelectTypes.TwoChanZoneSelectType) {
        return (
            <div
                className={`
                    ${props.className}
                `}
            >
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <Select
                            {...StandardSelectProps}
                            labelId="basic-select-label"
                            id="basic-select"
                            value={myVal.toString()}
                            label="Zone"
                            onChange={handleChange}
                            sx={{ color: "#FFFFFF" }}
                        >
                            <MenuItem value={0} key={key++}>
                                zone A
                            </MenuItem>
                            <MenuItem value={1} key={key++}>
                                zone B
                            </MenuItem>
                            <MenuItem value={2} key={key++}>
                                zone C
                            </MenuItem>
                            <MenuItem value={3} key={key++}>
                                zone W
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
        )
    } else if (props.type == SelectTypes.LocationType) {
        return (
            <div
                className={`
                    ${props.className}
                `}
            >
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <Select
                            {...StandardSelectProps}
                            labelId="basic-select-label"
                            id="basic-select"
                            value={myVal.toString()}
                            label="Location"
                            onChange={handleChange}
                            sx={{ color: "#FFFFFF" }}
                        >
                            {spatialLocations.map((val, index) => {
                                return (
                                    <MenuItem value={index} key={key++}>
                                        {val}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </div>
        )
    } else if (props.type == SelectTypes.LatencyType) {
        return (
            <div
                className={`
                    ${props.className}
                `}
            >
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <Select
                            {...StandardSelectProps}
                            labelId="basic-select-label"
                            id="basic-select"
                            value={myVal.toString()}
                            label="Latency"
                            onChange={handleChange}
                            disabled={props.disabled}
                        >
                            {latencyStrings.map((val, index) => {
                                return (
                                    <MenuItem value={index} key={key++}>
                                        {val}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </div>
        )
    } else if (props.type == SelectTypes.CaptureChansType) {
        return (
            <div
                className={`
                    ${props.className}
                `}
            >
                <FormControl fullWidth>
                    <Select
                        {...StandardSelectProps}
                        labelId="basic-select-label"
                        id="basic-select"
                        value={myVal.toString()}
                        onChange={handleChange}
                        disabled={props.disabled}
                    >
                        {
                            /*(props.device == null || (props.device?.activeInputSoundCard == null)) ? <></> : */
                            inputChannelCountPossibilities.map((val, index) => {
                                return (
                                    <MenuItem
                                        value={index}
                                        key={key++}
                                        className=""
                                    >
                                        {val}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        )
    } else if (props.type == SelectTypes.OutputChansType) {
        return (
            <div
                className={`
                    ${props.className}
                `}
            >
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <Select
                            {...StandardSelectProps}
                            labelId="basic-select-label"
                            id="basic-select"
                            value={myVal.toString()}
                            label="OutputChans"
                            onChange={handleChange}
                            disabled={props.disabled}
                        >
                            {outputChannelCountPossibilities.map(
                                (val, index) => {
                                    return (
                                        <MenuItem value={index} key={key++}>
                                            {val}
                                        </MenuItem>
                                    )
                                }
                            )}
                        </Select>
                    </FormControl>
                </Box>
            </div>
        )
    } // end else if
}
