import { useState, useEffect } from "react"

export const useDebounce = <T extends number | string | object>(
    value: T,
    delay: number
) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler)
            }
        },
        [value, delay] // Only re-call effect if value or delay changes
    )
    return debouncedValue
}

export const useDebounceQueue = (delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState<
        Record<string, () => void>
    >({})
    const addToQueue = (key: string, value: () => void) => {
        setDebouncedValue({ ...debouncedValue, [key]: value })
    }
    useEffect(() => {
        const handler = setTimeout(() => {
            Object.values(debouncedValue).forEach((func) => func())
            setDebouncedValue({})
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [debouncedValue, delay])
    return addToQueue
}

export const useDateFormatter = (date?: Date | string) => {
    if (date === undefined)
        return {
            dayMonth: "--",
            weekday: "-----",
            monthLong: "-----",
            monthShort: "---",
            time: "--:--",
        }
    if (typeof date === "string") date = new Date(date)
    return {
        dayMonth: date.toLocaleString("default", { day: "2-digit" }),
        weekday: date.toLocaleString("default", { weekday: "long" }),
        monthLong: date.toLocaleString("default", { month: "long" }),
        monthShort: date.toLocaleString("default", { month: "short" }),
        time: date.toLocaleTimeString("default", {
            hour12: true,
            timeStyle: "short",
        }),
    }
}

export const useInviteDateFormatter = (
    startDate?: Date | string,
    endDate?: Date | string
) => {
    if (startDate === undefined || endDate === undefined) return ""
    if (typeof startDate === "string") startDate = new Date(startDate)
    if (typeof endDate === "string") endDate = new Date(endDate)
    return `${startDate.toLocaleString("default", { day: "2-digit" })} ${startDate.toLocaleString("default", { month: "short" })} ${startDate.toLocaleTimeString(
        "default",
        {
            hour12: true,
            timeStyle: "short",
        }
    )} - ${endDate.toLocaleTimeString("default", {
        hour12: true,
        timeStyle: "short",
    })}`
}
