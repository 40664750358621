export default function Live(props: React.ComponentProps<"svg">) {
    return (
        <svg
            viewBox="0 0 19 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M16.4613 5.61442C15.9036 5.61442 15.3449 5.63523 14.7886 5.6073C14.3997 5.58769 14.0923 5.28755 14.0071 4.93073C13.9138 4.539 14.0516 4.14565 14.3813 3.93145C14.4986 3.85517 14.6461 3.78319 14.7805 3.78171C15.907 3.76949 17.0341 3.75673 18.16 3.78279C18.6005 3.79299 18.922 4.16579 18.963 4.58989C19.0051 5.02608 18.765 5.43312 18.3617 5.56513C18.2062 5.61603 18.0331 5.62812 17.8677 5.63121C17.399 5.63953 16.9302 5.63416 16.4613 5.63416V5.61442Z" />
            <path d="M9.50235 5.61436C8.94463 5.61436 8.38583 5.63598 7.82973 5.60711C7.42778 5.58629 7.11528 5.27151 7.03994 4.89844C6.95816 4.49287 7.12576 4.09402 7.47747 3.89473C7.58129 3.83591 7.70577 3.78125 7.82127 3.78031C8.94772 3.77145 10.0747 3.75788 11.2006 3.78273C11.6292 3.79213 11.9476 4.14949 11.9978 4.55666C12.0527 5.00104 11.8358 5.40513 11.433 5.55138C11.2697 5.61073 11.0845 5.62672 10.9085 5.63061C10.44 5.64109 9.97117 5.63424 9.50235 5.63424V5.61436Z" />
            <path d="M9.48084 1.91438C8.9896 1.91438 8.49835 1.91989 8.00724 1.9129C7.41621 1.90458 7.01615 1.51217 7.02354 0.960762C7.03052 0.430436 7.43287 0.0451474 8.00751 0.0412529C9.01216 0.0345382 10.0169 0.0342696 11.0217 0.0413872C11.6125 0.045416 12.0132 0.443194 12.0053 0.994201C11.9975 1.52775 11.5992 1.90512 11.0215 1.9129C10.5081 1.91989 9.99438 1.91438 9.48084 1.91438Z" />
            <path d="M9.52534 7.4747C10.0277 7.47497 10.5301 7.46892 11.0324 7.47644C11.6082 7.48517 12.0029 7.86778 12.0052 8.40522C12.0075 8.94253 11.614 9.34152 11.0442 9.34689C10.0284 9.35629 9.01248 9.35548 7.99654 9.34729C7.42445 9.34273 7.02614 8.95045 7.02345 8.41704C7.02063 7.86899 7.42405 7.48679 8.01816 7.4747C8.04059 7.4743 8.06288 7.47443 8.08518 7.47443C8.56528 7.47443 9.04524 7.47443 9.52534 7.4747Z" />
            <path d="M2.5446 7.47508C3.03571 7.47508 3.52709 7.472 4.0182 7.47589C4.64294 7.48073 5.05079 7.85769 5.04636 8.41904C5.04206 8.96695 4.63891 9.34566 4.03727 9.34888C3.0435 9.35439 2.04986 9.3552 1.05622 9.34848C0.467207 9.34446 0.0573427 8.94681 0.0605657 8.4044C0.0639231 7.86333 0.474728 7.48207 1.07086 7.47616C1.56184 7.47119 2.05335 7.47508 2.5446 7.47508Z" />
            <path d="M16.4773 9.35059C15.9749 9.35072 15.4724 9.3565 14.97 9.34911C14.3795 9.34051 13.9745 8.94462 13.9825 8.39737C13.9903 7.86301 14.389 7.48122 14.966 7.47746C15.9708 7.47101 16.9757 7.47115 17.9805 7.47732C18.5568 7.48095 18.9599 7.86315 18.9683 8.39321C18.9771 8.93871 18.5707 9.33971 17.9846 9.34884C17.4822 9.35677 16.9797 9.35045 16.4773 9.35059Z" />
            <path d="M2.57251 13.0637C2.07025 13.0634 1.56786 13.0718 1.06574 13.0613C0.454162 13.0487 0.0610846 12.6768 0.0605474 12.1301C0.0600103 11.5865 0.452685 11.1997 1.06211 11.191C2.05521 11.1769 3.04885 11.1777 4.04209 11.1906C4.6503 11.1985 5.04701 11.5846 5.04795 12.1266C5.04889 12.6727 4.65407 13.0417 4.04585 13.0633C4.02342 13.0641 4.00113 13.064 3.97884 13.064C3.51002 13.064 3.04133 13.064 2.57251 13.0637Z" />
            <path d="M9.50109 13.0636C9.00998 13.0635 8.51873 13.0714 8.02789 13.0615C7.41511 13.0491 7.02364 12.6806 7.02149 12.133C7.01935 11.5885 7.41108 11.1999 8.01889 11.1912C9.012 11.1769 10.0055 11.1776 10.9987 11.1906C11.6102 11.1987 12.0027 11.5801 12.0046 12.124C12.0063 12.6868 11.6118 13.0518 10.9743 13.0621C10.4833 13.0701 9.9922 13.0637 9.50109 13.0636Z" />
            <path d="M16.4724 13.0632C15.9813 13.0632 15.49 13.0702 14.9992 13.0615C14.384 13.0505 13.9884 12.6873 13.9806 12.1429C13.9727 11.5881 14.3771 11.1965 15.0028 11.1894C15.9849 11.1781 16.9673 11.1773 17.9494 11.1895C18.5777 11.1973 18.9756 11.5871 18.9668 12.1464C18.9582 12.6909 18.5643 13.0503 17.9456 13.0615C17.4548 13.0703 16.9635 13.0632 16.4724 13.0632Z" />
        </svg>
    )
}
