import { MasterChannel, MASTERCHANNELNUMBER } from "@/components/MasterMixer"
import { LocalAction, localReducer } from "@/reducers/localReducer"
import { PlayerAction, playerReducer } from "@/reducers/playerReducer"
import {
    Agent,
    Bonza,
    BonzaService,
    LocalDevice,
    LocalRemoteManager,
} from "@/services/BonzaService"
import LoggerService, { LogLevel } from "@/services/LoggerService"
import { NwkInfo } from "@/services/NwkInfoService"
import { getUserSessions } from "@/services/UserService"
import { Video } from "@/services/VideoService"
import {
    inputChannelCountPossibilities,
    LoopbackIP,
    LoopbackPort,
    setReceiverBufferSizeMessage,
    setScreenSharingMessage,
} from "@/types/AppMessage"
import {
    BonzaSessionResponse,
    BonzaSessionUpdatedProps,
} from "@/types/BonzaSession"
import { ConnectionWithUserResource } from "@/types/Connection"
import {
    AudioInitialisationSequenceStates,
    DeviceChangeEventType,
    IDevice,
} from "@/types/Device"
import {
    PlayerViewLocal,
    PlayerViewRemote,
    RTInfo,
    RTInfoEmptyField,
} from "@/types/PlayerView"
import {
    IRemoteManager,
    isIP,
    RemoteChangeEventType,
    RemoteConnectState,
    RemoteInfo,
} from "@/types/RemoteManager"
import { UserInvitationNotification } from "@/types/User"
import { User } from "@/types/UserClass"
import {
    MessageEventListener,
    ReadyState,
    SocketEvent,
    SocketEventListener,
} from "@/types/WebSocket"
import { router } from "@inertiajs/react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { enqueueSnackbar, SnackbarProvider } from "notistack"
import {
    createContext,
    Dispatch,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react"
import axios from "axios"

type BonzaContextProps = {
    user: User | undefined
    setUser: Dispatch<User | undefined>
    peers: ConnectionWithUserResource[] | undefined
    setPeers: Dispatch<ConnectionWithUserResource[] | undefined>
    players: PlayerViewRemote[]
    setPlayers: Dispatch<PlayerAction>
    locals: PlayerViewLocal[]
    setLocals: Dispatch<LocalAction>
    setInputChannelCount: (count: number) => void
    hideLocals: boolean
    screenSharing: boolean
    toggleScreenSharing: () => void
    connected: boolean
    skipSetup: boolean
    setSkipSetup: Dispatch<boolean>
    setupBackRoute: string
}

const BonzaContext = createContext<BonzaContextProps | undefined>(undefined)

export const BonzaContextProvider = ({ children }: PropsWithChildren) => {
    const [user, setUser] = useState<User | undefined>(undefined)

    const [peers, setPeers] = useState<
        ConnectionWithUserResource[] | undefined
    >(undefined)

    const [players, setPlayers] = useReducer(
        playerReducer,
        new Array<PlayerViewRemote>()
    )

    const connected = useMemo(
        () => Agent.readyState == ReadyState.Open,
        [Agent.readyState]
    )

    const logger = new LoggerService("Stage", LogLevel.NonTrivial)

    const [locals, setLocals] = useReducer(localReducer, [MasterChannel])

    const [hideLocals, setHideLocals] = useState(
        !LocalDevice.activeInputSoundCard
    )

    const setInputChannelCount = (count: number) => {
        const currentCount = locals.length - 1

        if (count < currentCount) {
            setLocals({
                action: "TRUNCATE",
                newLen: count + 1,
            })
        } else if (count > currentCount) {
            const newLocals = []
            for (let i = currentCount; i < count; i++) {
                newLocals.push({
                    name: `Channel ${i + 1}`,
                    agent: Agent,
                    channel: i,
                })
            }
            setLocals({
                action: "CREATE",
                playerArr: newLocals,
            })
        }
    }

    const [screenSharing, setScreenSharing] = useState(false)

    const toggleScreenSharing = () => {
        if (!screenSharing) {
            Agent.send(new setScreenSharingMessage(true))
            setScreenSharing(true)
        } else {
            Agent.send(new setScreenSharingMessage(false))
            setScreenSharing(false)
        }
    }

    const agentMessageListener: MessageEventListener = {
        handleMessageEvent(_: MessageEvent, message: any | null | undefined) {
            if (!message || !message.type) return

            switch (message.type) {
                case "sendVideoImage": {
                    if (Video.shouldUpdate("__SELF"))
                        Video.set(
                            "__SELF",
                            `data:image/jpeg;base64,${message.jpgBuffer}`
                        )
                    break
                }
                case "sendRemoteVideoImage":
                case "sendRemoteSoundLevel": {
                    /* also trap setRemoteSoundLevel here in case arrays out of step
                      TODO - if works refactor this out to sep function
                    */
                    let isVideo: boolean = false
                    let IP: string
                    let port: string
                    if (message.type == "sendRemoteVideoImage") {
                        // 240205+ new BonzaApp message for remote video:
                        IP = message.socketIP
                        port = message.portTCP
                        isVideo = true
                    } else {
                        //data1, data2, data3, data4
                        //ID,    value, IP,    port
                        IP = message.data3
                        port = message.data4
                    }

                    const ri: RemoteInfo | null =
                        LocalRemoteManager.findRemoteFromPortSkt(IP, port)
                    const pindex = players.findIndex(
                        (p) => p.ip === IP && p.port === port
                    )
                    if (ri) {
                        // found remote in LM's array
                        // now check display array for similar match ...
                        if (pindex > -1) {
                            // found it, so OK...
                            if (isVideo && Video.shouldUpdate(ri.remoteName)) {
                                Video.set(
                                    ri.remoteName,
                                    `data:image/jpeg;base64,${message.jpgBuffer}`
                                )
                            }
                        } else {
                            const player: PlayerViewRemote = {
                                name: ri.remoteName,
                                img: "", // no need for img yet - will do in next step...
                                agent: Agent,
                                ip: IP,
                                port: port,
                                readiness: RemoteConnectState.verified, // verified because we are getting this message!
                                pan: 0,
                            }
                            players.push(player)
                            // but now do an async update for GUI reasons... set the IMG
                            if (isVideo && Video.shouldUpdate(ri.remoteName)) {
                                Video.set(
                                    ri.remoteName,
                                    `data:image/jpeg;base64,${message.jpgBuffer}`
                                )
                            }
                        }
                    } else {
                        // no ri
                        // special cases since LH and Mirror do not (currently) get added to the array of remotes...
                        if (IP == LoopbackIP && port == LoopbackPort) {
                            return
                        }
                        if (isVideo) {
                            logger.warn(
                                `Stage:sendRemoteVideoImage - remote not found in remotes array ${IP}:${port}`
                            )
                        } else {
                            logger.warn(
                                `Stage:setRemoteSoundLevel - remote not found in remotes array ${IP}:${port}`
                            )
                        }
                        if (pindex > -1) {
                            const player = players[pindex]
                            setPlayers({
                                action: "DELETE",
                                partialPlayer: player,
                            })
                        }
                    }

                    break
                }

                // just handle self & any remotes' VIDEO here
                // - other messages eg setRemoteSoundLevel etc handled elsewhere
                case "tellLatency":
                    {
                        // Agent sending curr latency for display
                        const ip = message.data3
                        const port = message.data4
                        const info: RTInfo = {
                            latency: message.data2,
                            dropout: RTInfoEmptyField,
                        }
                        const player = players.find(
                            (p) => p.ip === ip && p.port === port
                        )
                        if (player) {
                            NwkInfo.set(player.name, info)
                        }
                    }
                    break
                case "tellDropout":
                    {
                        // Agent sending curr dropout for display
                        const ip = message.data3
                        const port = message.data4
                        const info: RTInfo = {
                            dropout: `${message.data2}`,
                        }
                        const logDropout: boolean = false
                        if (logDropout) {
                            logger.info(
                                `Stage:tellDropout ${ip}:${port} ${info.dropout}`
                            )
                        }
                        const player = players.find(
                            (p) => p.ip === ip && p.port === port
                        )
                        if (player) {
                            NwkInfo.set(player.name, info)
                        }
                    }
                    break
                case "setJitterBuffer": {
                    // Agent sending curr jitter suggestion - we dont have to do it if already in 'manual' mode
                    const ip = message.IP
                    const port = message.portTCP
                    const info: RTInfo = {
                        jitter: message.newJBValue,
                        dropout: RTInfoEmptyField,
                    }
                    const player = players.find(
                        (p) => p.ip === ip && p.port === port
                    )
                    if (player) {
                        if (LocalDevice.isAutoJB()) {
                            NwkInfo.set(player.name, info)
                            Agent.send(
                                new setReceiverBufferSizeMessage(
                                    message.IP,
                                    message.portTCP,
                                    message.newJBValue
                                )
                            )
                        } else {
                            // manual jb - just use the (global) manual one of this remote
                            info.jitter =
                                LocalDevice.deviceDataStore.advancedSettings.manualJitterIndex
                            NwkInfo.set(player.name, info)
                        }
                    }
                    break
                }
                default: {
                    logger.log("No valid message")
                    break
                }
            }
        },
    }

    const stageRemoteChangeListener = {
        handleRemoteChange(
            _: IRemoteManager,
            eventType: RemoteChangeEventType,
            remoteinfo: RemoteInfo
        ) {
            const name: string = remoteinfo.remoteName
            //
            // both below can be empty IF we havent got relevant info yet from server...
            //var IP: string = remoteinfo.engineIP; // pre 240711
            let IP: string = remoteinfo.remoteTCPAddr
            const port: string = remoteinfo.remoteTCPPort
            const readiness: RemoteConnectState = remoteinfo.remoteConnectState
            if (IP.length == 0 || port.length == 0) {
                // not had update with IP and port yet..
                IP = name // substitute name for now till update arrives...
            }

            switch (eventType) {
                case RemoteChangeEventType.Add: {
                    const IPstr: string = isIP(IP) ? ` ${IP}` : ``
                    logger.info(`RCET Adding ${name}${IPstr}`)
                    const player = {
                        name: name,
                        //img: "",
                        agent: Agent,
                        ip: IP,
                        port: port,
                        readiness: readiness,
                        pan: 0,
                    }
                    setPlayers({
                        action: "CREATE",
                        player: player,
                    })
                    enqueueSnackbar(`You're now connected with ${name}.`, {
                        variant: "success",
                    })
                    break
                }
                case RemoteChangeEventType.Remove: {
                    router.reload({ only: ["peers"] })
                    const playerlenpre: number = players.length
                    logger.info(
                        `RCET attempt remove ${name} (${IP}) from players (len ${playerlenpre})`
                    )
                    const player = players.find((p) => p.name == name)

                    const assumePlayersLenNotValid = true // 240605KB

                    if (player || assumePlayersLenNotValid) {
                        // if assumePlayersLenNotValid & !player
                        // send delete anyway...
                        setPlayers({
                            action: "DELETE",
                            partialPlayer: {
                                name: name,
                            },
                        })
                        enqueueSnackbar(`You're disconnected from ${name}.`, {
                            variant: "warning",
                        })
                    } else {
                        enqueueSnackbar(
                            `attempt disconnect from ${name} - wasnt connected, ignoring`,
                            {
                                variant: "info",
                            }
                        )
                        // poss out of step here KB 240705
                    }
                    break
                }
                case RemoteChangeEventType.Readyness:
                    logger.info(`RCET Readiness ${IP} = ${readiness}`)
                    setPlayers({
                        action: "EDIT",
                        partialPlayer: {
                            name: name,
                            readiness: readiness,
                        },
                    })
                    break
                case RemoteChangeEventType.RemoveAll: {
                    const playerlen: number = players.length
                    logger.info(
                        `RCET Remove All from players (len ${playerlen})`
                    )
                    setPlayers({
                        action: "SET",
                        playerArr: undefined, // 240327 this should zap the players array
                    })
                    break
                }
                default:
                    logger.error(`Unknown RemoteChangeEventType ${eventType}`)
                    break
            }
        },
    }

    const stageDeviceChangeListener = {
        handleDeviceChange(device: IDevice, eventType: DeviceChangeEventType) {
            switch (eventType) {
                case DeviceChangeEventType.ActiveSoundCard: {
                    const sc = device.activeInputSoundCard
                    if (!sc) {
                        setHideLocals(true)
                        return
                    }
                    if (hideLocals) setHideLocals(false)
                    const scInsCount = sc.inputChannels
                    if (!scInsCount || scInsCount == 0) {
                        return
                    }
                    const inUseCount =
                        inputChannelCountPossibilities[
                            Number(
                                LocalDevice.getSavedSettings().inChannelsIndex
                            )
                        ]
                    if (!inUseCount || inUseCount == 0) {
                        return
                    }
                }
            }
        },
    }

    const queryClient = useQueryClient()

    const { data: dataSessions } = useQuery<BonzaSessionResponse[]>({
        queryKey: ["sessions"],
        queryFn: getUserSessions,
    })

    const sessionUpdateCallback = async (data: BonzaSessionUpdatedProps) => {
        if (data.activated_at) {
            enqueueSnackbar(`Session started: ${new Date(data.activated_at)}`)
        } else if (data.deactivated_at) {
            enqueueSnackbar(`Session ended: ${new Date(data.deactivated_at)}`)
            router.reload({ only: ["peers"] })
        } else if (data.joined) {
            enqueueSnackbar(`User joined: ${data.joined.user.id}`)
            router.reload({ only: ["peers"] })
        } else if (data.left) {
            enqueueSnackbar(`User left: ${data.left.user.id}`)
            router.reload({ only: ["peers"] })
        } else {
            enqueueSnackbar(`Session updated: ${data.name}`)
        }
    }

    useEffect(() => {
        players.forEach((player) => {
            if (
                peers === undefined ||
                peers.length === 0 ||
                peers?.findIndex((peer) => peer.user.name === player.name) ===
                    -1
            ) {
                LocalRemoteManager.guiReqDisconnectFromName(player.name)
            }
        })
    }, [peers?.length])

    const [sessionLength, setSessionLength] = useState(dataSessions?.length)

    useEffect(() => {
        if (user?.id !== undefined && sessionLength !== dataSessions?.length) {
            window.Echo.disconnect()
            window.Echo.connect()

            dataSessions?.forEach((session) => {
                window.Echo.private(`Bonza.Session.${session.id}`)
                    .error((error: unknown) => console.error(error))
                    .subscribed(() =>
                        console.log(`Subscribed to ${session.id}`)
                    )
                    .listen("BonzaSessionUpdated", sessionUpdateCallback)
            })

            window.Echo.private(`App.Models.User.${user.id}`).notification(
                async ({
                    bonza_session,
                    invited_by,
                }: UserInvitationNotification) => {
                    enqueueSnackbar(
                        `You have been invited to ${bonza_session.name} by ${invited_by.name}`
                    )
                    await queryClient.invalidateQueries({
                        queryKey: ["invites"],
                    })
                }
            )

            setSessionLength(dataSessions?.length)
        }
    }, [dataSessions?.length])

    const [remoteListenerReady, setRemoteListenerReady] = useState(false)
    const [deviceListenerReady, setDeviceListenerReady] = useState(false)

    useEffect(() => {
        if (user !== undefined) {
            if (BonzaService.user === null) BonzaService.user = user

            const heartbeat = setInterval(async () => {
                axios.get(route("api.connection.heartbeat")).catch((error) => {
                    logger.warn(`Connection heartbeat failed: ${error}`)
                    //TODO - handle failure here!
                })
            }, 10000)

            const bonzaEventListener: SocketEventListener = {
                handleSocketEvent(event: SocketEvent) {
                    logger.trivial(event)
                    if (event.type == "open") {
                        LocalRemoteManager.setOpen()
                        if (user.name != BonzaService.user?.name) {
                            logger.warn(
                                `auth.user:${user.name} != BonzaService.user:${BonzaService.user?.name}`
                            )
                        }
                        LocalRemoteManager.login(user)
                    }
                },
            }

            //TODO - temporary fake locations, **set fakeLocationCount between 1 and 8 to test**
            const fakeLocationCount = 0
            const imgs = Object.values(
                import.meta.glob("@img/player*.{png,jpg}", {
                    eager: true,
                    as: "url",
                })
            )
            if (fakeLocationCount > 0) {
                console.log("Adding fakes")
                for (let i = 0; i <= fakeLocationCount - 1; i++) {
                    players.push({
                        name: i ? `Location ${i + 1}` : `Me`,
                        img: imgs[i],
                        agent: Agent,
                        ip: `127.0.0.${i + 1}`,
                        port: undefined,
                        pan: 0,
                    })
                    logger.log(`Location ${i + 1}: ${imgs[i]}`)
                }
                setPlayers({
                    action: "SET",
                    playerArr: players,
                })
            }

            if (!LocalDevice.fakeLocals) {
                LocalDevice.fakeLocals = [
                    {
                        name: `Master`,
                        agent: Agent,
                        channel: MASTERCHANNELNUMBER,
                    },
                ]
                setLocals({
                    action: "SET",
                    playerArr: LocalDevice.fakeLocals,
                })
            }

            const count =
                inputChannelCountPossibilities[
                    Number(LocalDevice.getSavedSettings().inChannelsIndex)
                ]
            setInputChannelCount(count)

            if (Bonza.readyState !== ReadyState.Open) {
                Bonza.join()
                Bonza.addEventListener(bonzaEventListener)
                Bonza.open()
            }

            if (Agent.readyState !== ReadyState.Open) {
                Agent.addMessageListener(agentMessageListener)
                Agent.connect()
            }

            if (!remoteListenerReady) {
                LocalRemoteManager.addRemoteChangeListener(
                    stageRemoteChangeListener
                )
                setRemoteListenerReady(true)
            }

            if (!deviceListenerReady) {
                LocalDevice.addDeviceChangeListener(stageDeviceChangeListener)
                setDeviceListenerReady(true)
            }

            return () => clearTimeout(heartbeat)
        }
    }, [user])

    const [skipSetup, setSkipSetup] = useState(false)
    const [setupBackRoute, setSetupBackRoute] = useState(route("dashboard"))

    useEffect(() => {
        if (
            user &&
            LocalDevice.initState & AudioInitialisationSequenceStates.AISS_3 &&
            (LocalDevice.activeInputSoundCard === null ||
                LocalDevice.activeOutputSoundCard === null)
        ) {
            if (!skipSetup) {
                setSetupBackRoute(route("dashboard"))
                router.get(route("firstsetup"))
            } else if (route().current() === "stage") {
                setSetupBackRoute(route("stage"))
                router.get(route("firstsetup"))
            }
        }
    }, [user, LocalDevice.initState])

    return (
        <BonzaContext.Provider
            value={{
                players,
                setPlayers,
                peers,
                setPeers,
                user,
                setUser,
                locals,
                setLocals,
                setInputChannelCount,
                hideLocals,
                screenSharing,
                toggleScreenSharing,
                connected,
                skipSetup,
                setSkipSetup,
                setupBackRoute,
            }}
        >
            <SnackbarProvider maxSnack={5} disableWindowBlurListener={true}>
                {children}
            </SnackbarProvider>
        </BonzaContext.Provider>
    )
}

export function useBonzaContext(): BonzaContextProps {
    const ctx = useContext(BonzaContext)
    if (ctx === undefined) throw Error("Context is undefined")
    return ctx
}

export default BonzaContext
