export default function Home(props: React.ComponentProps<"svg">) {
    return (
        <svg
            viewBox="0 0 16 17"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M15.9716 16.5983H10.5791V16.3942C10.579 15.0645 10.5842 13.7348 10.5772 12.4052C10.5708 11.1735 9.73221 10.151 8.5318 9.89174C6.9372 9.54737 5.42242 10.7475 5.40834 12.3802C5.3969 13.7098 5.40564 15.0395 5.40543 16.3692V16.591H0.0129271V16.3974C0.0129271 13.2003 0.0132114 10.0032 0.0117188 6.80603C0.0117188 6.69607 0.0222381 6.61284 0.126152 6.53522C2.70992 4.605 5.29014 2.66994 7.87107 0.735951C7.90839 0.707947 7.9472 0.682004 7.99624 0.647461C8.44125 0.980312 8.88512 1.31188 9.32842 1.6441C11.4746 3.2522 13.6195 4.86194 15.769 6.46564C15.9149 6.57446 15.977 6.68121 15.9766 6.86978C15.9696 10.0314 15.9716 13.1931 15.9716 16.3548V16.5983Z" />
        </svg>
    )
}
