import { useEffect, useState } from "react"
import { SelectChangeEvent } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { inputChannelCountPossibilities } from "@/types/AppMessage"
import { StandardSelectProps } from "@/types/StandardSelectProps"
import { SelectorExtraProps } from "@/types/DeviceSelector"
import { Agent, LocalDevice } from "@/services/BonzaService"
import { checkOKToStart } from "../SelectMenu"
import { sendSaveSettings } from "@/types/Device"

export default function InputChannelsSelector(props: SelectorExtraProps) {
    //    const [value, setValue] = useState(props.value ?? props.device.deviceDataStore.inChansSelectIndex);
    const [value, setValue] = useState(
        props.value ?? props.device.getSavedSettings().inChannelsIndex
    )
    const [disabledOptions, setDisabledOptions] = useState(new Array<boolean>())

    const handleChange = (event: SelectChangeEvent) => {
        updateIndex(JSON.parse(event.target.value))
    }

    const updateIndex = (index: number) => {
        setValue(index)

        //props.device.deviceDataStore.inChans = inputChannelCountPossibilities[index];
        //props.device.deviceDataStore.inChansSelectIndex = index;

        const settings = props.device.getSavedSettings()
        if (settings.inChannelsIndex != `${index}`) {
            settings.inChannelsIndex = `${index}`
            props.device.saveSavedSettings(settings)
            sendSaveSettings(Agent, settings)
        }

        if (checkOKToStart(props)) {
            const inhibRequestNewSettings: boolean = true
            props.device.sequence_Start(inhibRequestNewSettings)
        }

        if (props.handleChange)
            props.handleChange({
                target: {
                    name: props.name ?? "InputChannelsSelector",
                    value: value,
                },
            })
    }

    function LimitValue(inval: number | string | readonly string[]): string {
        const dev = LocalDevice
        const card = dev.activeInputSoundCard
        if (!card) {
            return "0"
        }
        const maxchans = card.inputChannels
        let valn = inval instanceof Array ? inval[0] : inval
        valn = Number(valn)
        while (valn > 0) {
            const thisnc = inputChannelCountPossibilities[valn]
            if (thisnc > maxchans) {
                --valn
                setValue(valn)
            } else {
                break
            }
        }
        return valn.toString()
    }

    let update = false
    const incard = LocalDevice.activeInputSoundCard
    const maxchans = incard?.inputChannels
    let maxIndex = 0
    inputChannelCountPossibilities.map((count, index) => {
        const disable =
            !incard ||
            !maxchans ||
            inputChannelCountPossibilities[index] > maxchans
        if (!disable) maxIndex = index
        update ||= disable !== disabledOptions[index]
        disabledOptions[index] = disable
    })
    if (update) {
        setDisabledOptions([...disabledOptions])
        if (maxIndex < +value) updateIndex(maxIndex)
    }

    useEffect(() => {
        if (props.handleChange) {
            props.handleChange({
                target: {
                    name: props.name ?? "InputChannelsSelector",
                    value: value,
                },
            })
        }
    }, [value, disabledOptions, props.disabled])

    const id = props.id ?? "bonza-input-channel-select"
    return (
        <FormControl>
            <Select
                value={LimitValue(value)}
                onChange={handleChange}
                {...StandardSelectProps}
                id={id}
                labelId={`${id}-label`}
                disabled={props.disabled}
                name={props.name ?? "InputChannelsSelector"}
            >
                {inputChannelCountPossibilities.map((count, index) => (
                    <MenuItem
                        key={index}
                        value={index}
                        disabled={disabledOptions[index]}
                    >
                        {count}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
