import { amplitude2dBSPLMapToMinMax, VuMeterProps } from "@/components/VuMeter"
import VuBackground, {
    VuBackgroundProps,
} from "@/components/parts/VuBackground"

import { useEffect, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { LR_Type } from "./AudioControlLocal"

export default function VuMeterMini(props: VuMeterProps) {
    const columns = props.columns ?? 2
    const bg = VuBackground({
        width: 7 * columns,
        fill: props.bgColor ?? undefined,
        columns: columns,
        gapAt: props.gapAt ?? undefined,
    })
    const encoded = encodeURIComponent(ReactDOMServer.renderToString(bg))

    const min = props.min ?? 0
    const max = props.max ?? 10

    const hiColor = props.hiColor ?? "#455DFF"
    const loColor = props.loColor ?? "#707070"
    const dangerColor = props.dangerColor ?? "#f57d9d"
    const dangerLevel = props.dangerLevel ?? 0

    const [level, setLevel] = useState(props.value ?? 0)
    const danger = dangerLevel ? level - dangerLevel : 0

    // useEffect(() => {
    //     const fakeMeterSignals : boolean = truez; // test audio in
    //     const updatems : number = 200;
    //     const x = setInterval(() => {
    //         if (fakeMeterSignals) {
    //             let v = Math.random();
    //             v = amplitude2dBSPLMapToMinMax(v,-20,3,min,max);
    //             setLevel(v*10);
    //         }
    //     }, updatems);

    //     return () => {
    //         // clearInterval(x);
    //     }

    // }, []);

    useEffect(() => {
        const agentMessageListener = {
            handleMessageEvent(event: MessageEvent) {
                const data = JSON.parse(event.data)
                // 240327 - setLocalSoundLevelV1 is NOT IMPLEMENTED in BA @ pres...
                if (
                    data.type == "setLocalSoundLevelV1" &&
                    props.lrtype == LR_Type.Local
                ) {
                    if (data.channel === props.channel) {
                        const chanLevelF: number = data.level0 // 0.0 to 1.0
                        const chanlevelDisp = amplitude2dBSPLMapToMinMax(
                            chanLevelF,
                            -20,
                            3,
                            min,
                            max
                        )
                        setLevel((chanlevelDisp * 100) / max)
                    }
                } else if (
                    data.type == "setLocalSoundLevel" &&
                    props.lrtype == LR_Type.Local
                ) {
                    // channelCount : string   // num chans (eg "2")
                    // maxSampleValue : string  // `;` delimited string of floats eg "0;0.5"
                    if (props.channel === -1) {
                        return // this message setLocalSoundLevel does not handle MASTER (-1)
                    }
                    const numChansInMsg: number = Number(data.channelCount)
                    if (typeof numChansInMsg != typeof 1) {
                        return
                    }
                    if (
                        props.channel == null ||
                        props.channel >= numChansInMsg
                    ) {
                        // valid channels are 0:(MAX-1)
                        return
                    }
                    const maxSampleValuesStr: string = data.maxSampleValue
                    let chanLevels
                    if (maxSampleValuesStr && maxSampleValuesStr.length) {
                        chanLevels = maxSampleValuesStr.split(";")
                    } else {
                        return
                    }
                    if (chanLevels.length <= numChansInMsg) {
                        return
                    }
                    if (props.channel >= 0) {
                        const chanLevelF: number = Number(
                            chanLevels[props.channel]
                        ) // 0.0 to 1.0
                        const chanlevelDisp = amplitude2dBSPLMapToMinMax(
                            chanLevelF,
                            -20,
                            3,
                            min,
                            max
                        )
                        // dB = 20 * log10(amplitude)
                        // vu meter should display -20 to +3dB (SPL)
                        // but std range on this meter is 0:100.

                        setLevel((chanlevelDisp * 100) / max)
                    }
                }
            },
        }

        props.agent.addMessageListener(agentMessageListener)

        const fakeMeterSignals: boolean = false // test audio in
        const updatems: number = 200
        const x = setInterval(() => {
            if (fakeMeterSignals) {
                let v = Math.random()
                v = amplitude2dBSPLMapToMinMax(v, -20, 3, min, max)
                setLevel(v * 10)
            }
        }, updatems)

        return () => {
            // clearInterval(x);
            props.agent.removeMessageListener(agentMessageListener)
        }
    }, [props.channel])

    return (
        <div
            className={`
                ${props.className ?? ""}

                flex flex-row
            `}
        >
            <div
                className={`
                    flex-1

                    h-${3.2 * columns}

                    flex flex-col py-2
                `}
                style={{}}
            >
                <div
                    className={`
                        flex-1

                        h-${2.5 * columns}
                        ${props.invert ? `rotate-180` : ""}
                    `}
                    style={{
                        backgroundColor: `${loColor}`,
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: `${hiColor}`,
                            width: `${props.inactive ? 0 : level}%`,
                            position: "absolute",
                            left: 0,
                            height: "100%",
                        }}
                    />
                    {!props.inactive && danger > 0 ? (
                        <div
                            style={{
                                backgroundColor: `${dangerColor}`,
                                width: `${danger}%`,
                                position: "absolute",
                                left: `${dangerLevel}%`,
                                height: "100%",
                            }}
                        />
                    ) : (
                        ""
                    )}
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundImage: `url("data:image/svg+xml;charset=UTF-8,${encoded}")`,
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
