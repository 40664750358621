import { SelectorExtraProps, SelectorProps } from "@/types/DeviceSelector"
import { useState } from "react"
import { SelectChangeEvent } from "@mui/material"
import { Agent, LocalDevice } from "@/services/BonzaService"
import { diffuseIRDepthMessageV240124 } from "@/types/AppMessage"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { StandardSelectProps } from "@/types/StandardSelectProps"
import { sendSaveSettings } from "@/types/Device"

export default function DiffuseIRDepthSelector(props: SelectorProps) {
    const [depthIndex, setDepthIndex] = useState(
        parseInt(LocalDevice.getSavedSettings().diffuseIRLevelIndex ?? "0")
    )

    const handleChange = (event: SelectChangeEvent) => {
        const depthIndex = parseInt(event.target.value)
        const settings = LocalDevice.getSavedSettings()
        if (settings.diffuseIRLevelIndex != `${depthIndex}`) {
            settings.diffuseIRLevelIndex = `${depthIndex}`
            LocalDevice.saveSavedSettings(settings)
            Agent.send(new diffuseIRDepthMessageV240124(depthIndex))
            sendSaveSettings(Agent, settings)
        }
        setDepthIndex(depthIndex)

        if (props.handleChange) props.handleChange(event)
    }

    const values = []
    for (let i = 0; i < 5; i++) {
        values.push(
            <MenuItem value={i} key={i}>
                {i == 0 ? "Diffuse IR off" : `Diffuse IR depth ${i}`}
            </MenuItem>
        )
    }
    values.push(
        <MenuItem value={5} key={5}>
            Mute locals
        </MenuItem>
    )

    return (
        <FormControl fullWidth>
            <Select
                {...StandardSelectProps}
                value={`${depthIndex}`}
                disabled={props.disabled}
                onChange={(e) => handleChange(e)}
            >
                {values}
            </Select>
        </FormControl>
    )
}
