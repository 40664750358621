import { useState } from "react"
import { SelectChangeEvent } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { latencyStrings } from "@/types/AppMessage"
import { StandardSelectProps } from "@/types/StandardSelectProps"
import { SelectorExtraProps } from "@/types/DeviceSelector"
import { sendSaveSettings } from "@/types/Device"
import { Agent } from "@/services/BonzaService"
import { checkOKToStart } from "../SelectMenu"

export default function LatencySelector(props: SelectorExtraProps) {
    const [value, setValue] = useState(
        props.value ??
            props.device.deviceDataStore.getSavedSettings().frameSizeIndex
    )

    const handleChange = (event: SelectChangeEvent) => {
        const index: number = JSON.parse(event.target.value)
        setValue(index)

        const settings = props.device.getSavedSettings()
        if (settings.frameSizeIndex != `${index}`) {
            settings.frameSizeIndex = `${index}`
            props.device.saveSavedSettings(settings)
            sendSaveSettings(Agent, settings)
        }

        if (checkOKToStart(props)) {
            const inhibRequestNewSettings: boolean = true
            props.device.sequence_Start(inhibRequestNewSettings)
        }

        if (props.handleChange) props.handleChange(event)
    }

    const id = props.id ?? "bonza-latency-select"
    return (
        <FormControl>
            <Select
                value={`${value}`}
                onChange={handleChange}
                {...StandardSelectProps}
                id={id}
                labelId={`${id}-label`}
                disabled={props.disabled}
            >
                {latencyStrings.map((count, index) => (
                    <MenuItem key={index} value={index}>
                        {count}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
