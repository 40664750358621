import { SelectorProps } from "@/types/DeviceSelector"
import { useState } from "react"
import { Agent, LocalDevice } from "@/services/BonzaService"
import { setVideoResolutionMessage } from "@/types/AppMessage"
import { sendSaveSettings } from "@/types/Device"
import SelectInput from "../formInputs/SelectInput"

const ResNames = ["Max", "Med", "Small", "VSmall"]
export const defaultResIndex: number = 2
export default function VideoResolutionSelector(props: SelectorProps) {
    const [resIndex, setResIndex] = useState<number>(() =>
        parseInt(
            LocalDevice.getSavedSettings()?.videoResolutionIndex ??
                defaultResIndex.toString()
        )
    )

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const resIndex = parseInt(event.target.value)
        const settings = LocalDevice.getSavedSettings()
        if (settings.videoResolutionIndex != `${resIndex}`) {
            settings.videoResolutionIndex = `${resIndex}`
            LocalDevice.saveSavedSettings(settings)
            Agent.send(new setVideoResolutionMessage(resIndex))
            sendSaveSettings(Agent, settings)
        }
        setResIndex(resIndex)
        if (props.handleChange)
            props.handleChange({
                target: {
                    name: props.name ?? "VideoResolutionSelector",
                    value: resIndex,
                },
            })
    }

    const values = []
    for (const i in ResNames) {
        values.push(
            <option value={i} key={i} selected={i == ResNames[resIndex]}>
                Res {ResNames[i]}
            </option>
        )
    }

    return (
        <SelectInput
            value={`${resIndex}`}
            disabled={props.disabled}
            onChange={(e) => handleChange(e)}
        >
            {values}
        </SelectInput>
    )
}
