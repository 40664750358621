import { useBonzaContext } from "@/context/BonzaContext"
import { ConnectionBonzaSessionResource } from "@/types/Connection"
import { Link } from "@inertiajs/react"
import { ChevronRight, Circle } from "@mui/icons-material"
import { useMemo } from "react"

type HeaderMiddleProps = {
    connection?: ConnectionBonzaSessionResource
}

export type Breadcrumb = {
    label: string
    route?: string
}

export default function HeaderMiddle({ connection }: HeaderMiddleProps) {
    const context = useBonzaContext()
    const routeName = route().current()

    const breadcrumbs = useMemo<Breadcrumb[]>(() => {
        if (routeName === undefined) return []
        switch (routeName) {
            case "session":
            case "session.profile":
            case "group":
            case "group.profile":
                return [
                    {
                        label: "Home",
                        route: "dashboard",
                    },
                    {
                        label:
                            routeName.charAt(0).toUpperCase() +
                            routeName.split(".")[0].slice(1),
                        route: routeName.split(".")[0],
                    },
                ]
            default:
                return []
        }
    }, [routeName])

    return context.players.length > 0 ? (
        <div
            className={`
                fixed left-1/2 top-0 flex h-16 -translate-x-1/2 flex-row
                items-center justify-center pt-6
            `}
        >
            <Link
                href={route("stage")}
                className={`
                    inline-flex h-fit items-center space-x-2 rounded-full
                    bg-bonza-dark-semi px-4 py-3
                `}
            >
                <Circle className="h-4 w-4 text-bonza-primary" />
                <p className="text-xl text-bonza-primary">Live:</p>
                <p className="text-xl text-bonza-pale">
                    {connection?.bonza_session?.name ?? "Your session"}
                </p>
            </Link>
        </div>
    ) : (
        <div className="flex h-full grow items-end self-start pl-[6%]">
            {routeName !== "dashboard" ? (
                <ul
                    className={`
                        inline-flex translate-y-1.5 gap-4 text-4xl
                        text-bonza-pale
                    `}
                >
                    {breadcrumbs.map((crumb, index) => (
                        <li
                            key={`crumb ${index}`}
                            className={`inline-flex items-center gap-4`}
                        >
                            {index !== 0 && (
                                <ChevronRight className={`text-bonza-pale`} />
                            )}
                            <Link href={route(crumb.route ?? "dashboard")}>
                                {crumb.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className={`translate-y-1.5 text-4xl text-bonza-pale`}>
                    {context.user
                        ? `Welcome back ${context.user?.name.split(" ")[0]}!`
                        : "Hello!"}
                </p>
            )}
        </div>
    )
}
