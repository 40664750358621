import {
    Menu,
    MenuButton,
    MenuHeading,
    MenuItem,
    MenuItems,
    MenuSection,
} from "@headlessui/react"
import { InviteResponse } from "@/types/Invite"
import { PropsWithChildren, useMemo } from "react"
import { GroupResponse } from "@/types/Group"
import { BonzaSessionResponse } from "@/types/BonzaSession"
import { useInviteDateFormatter } from "@/services/helpers/Hooks"

type HeaderInvitesProps = {
    icon: JSX.Element
    invites?: InviteResponse
    groups?: GroupResponse[]
    sessions?: BonzaSessionResponse[]
    groupCallback: (id: number, accept: boolean) => void
    sessionCallback: (id: number, accept: boolean) => void
}

export default function HeaderInvites({
    icon,
    invites,
    groups,
    sessions,
    groupCallback,
    sessionCallback,
}: HeaderInvitesProps) {
    const { groupInvites, sessionInvites } = useMemo(() => {
        const groupInvites = invites?.group
            .filter((inv) => !(inv.revoked_at && inv.accepted_at))
            .map((inv) => {
                const groupIndex =
                    groups?.findIndex((group) => group.id === inv.group_id) ??
                    -1
                if (groups !== undefined && groupIndex !== -1)
                    return {
                        ...inv,
                        group: groups[groupIndex],
                    }
                else
                    return {
                        ...inv,
                        group: undefined,
                    }
            })
        const sessionInvites = invites?.bonza_session
            .filter((inv) => !(inv.revoked_at && inv.accepted_at))
            .map((inv) => {
                const bonzaSessionIndex =
                    sessions?.findIndex(
                        (bonzaSession) =>
                            bonzaSession.id === inv.bonza_session_id
                    ) ?? -1
                if (sessions !== undefined && bonzaSessionIndex !== -1)
                    return {
                        ...inv,
                        bonzaSession: sessions[bonzaSessionIndex],
                    }
                else
                    return {
                        ...inv,
                        bonzaSession: undefined,
                    }
            })
        return {
            groupInvites,
            sessionInvites,
        }
    }, [invites, groups?.length, sessions?.length])

    const numInvites = useMemo(() => {
        return (groupInvites?.length ?? 0) + (sessionInvites?.length ?? 0)
    }, [groupInvites?.length, sessionInvites?.length])

    return (
        <Menu>
            <MenuButton className="group">
                <div
                    className={`
                        relative rounded-full bg-bonza-dark-semi p-3
                        text-bonza-pale-t-70 ring-0 transition duration-100
                        ease-in-out

                        group-hover:ring group-hover:ring-bonza-primary
                    `}
                >
                    {icon}
                    {numInvites > 0 && (
                        <span
                            className={`
                                absolute -right-0.5 -top-0.5 h-4 w-4
                                rounded-full bg-bonza-primary text-xs
                                text-bonza-pale
                            `}
                        >
                            {numInvites}
                        </span>
                    )}
                </div>
            </MenuButton>
            <MenuItems
                anchor="bottom end"
                transition
                className={`
                    group mt-1 flex min-w-52 origin-top-right flex-col
                    rounded-xl border border-bonza-dark bg-bonza-dark-semi p-1
                    text-sm/6 text-bonza-pale transition duration-100 ease-out

                    data-[closed]:scale-95 data-[closed]:opacity-0

                    focus:outline-none
                `}
            >
                {groupInvites?.length === 0 && sessionInvites?.length == 0 && (
                    <MenuSection
                        className={`
                            border-b border-bonza-pale-semi pb-2

                            group-last:border-b-0 group-last:pb-0
                        `}
                    >
                        <MenuItem>
                            <span
                                className={`
                                    flex w-full items-center rounded-lg px-3
                                    py-1.5
                                `}
                            >
                                No pending invites
                            </span>
                        </MenuItem>
                    </MenuSection>
                )}
                {groupInvites && groupInvites.length !== 0 && (
                    <>
                        <h2 className="pl-1 text-lg text-bonza-pale">
                            {"Groups"}
                        </h2>
                        {groupInvites?.map((invite) => (
                            <MenuSection
                                key={`invite-${invite.id}`}
                                className={`
                                    flex flex-col items-stretch gap-2 border-b
                                    border-bonza-pale-semi pb-2

                                    group-last:border-b-0 group-last:pb-0
                                `}
                            >
                                <MenuHeading className="px-3 py-1.5">
                                    {`Invite from ${invite.group?.name ?? `Group ${invite.group_id}`}`}
                                </MenuHeading>
                                <MenuItem>
                                    <button
                                        className={`
                                            flex w-full items-center rounded-lg
                                            py-1.5 pl-5 pr-3

                                            data-[focus]:bg-bonza-dark
                                        `}
                                        onClick={() =>
                                            groupCallback(invite.id, true)
                                        }
                                    >
                                        {"Accept"}
                                    </button>
                                </MenuItem>
                                <MenuItem>
                                    <button
                                        className={`
                                            flex w-full items-center rounded-lg
                                            py-1.5 pl-5 pr-3

                                            data-[focus]:bg-bonza-dark
                                        `}
                                        onClick={() =>
                                            groupCallback(invite.id, false)
                                        }
                                    >
                                        {"Reject"}
                                    </button>
                                </MenuItem>
                            </MenuSection>
                        ))}
                    </>
                )}
                {sessionInvites && sessionInvites.length !== 0 && (
                    <>
                        <h2 className="pl-1 text-lg text-bonza-pale">
                            {"Sessions"}
                        </h2>
                        {sessionInvites?.map((invite) => (
                            <MenuSection
                                key={`invite-${invite.id}`}
                                className={`
                                    flex flex-col items-stretch gap-2 border-b
                                    border-bonza-pale-semi pb-2

                                    group-last:border-b-0 group-last:pb-0
                                `}
                            >
                                <MenuHeading
                                    className={`flex flex-col px-3 pt-1.5`}
                                >
                                    <p>{`Invite to ${invite.bonzaSession?.name ?? `Session ${invite.bonza_session_id}`}`}</p>
                                    <p>
                                        {useInviteDateFormatter(
                                            invite.bonzaSession?.starts_at,
                                            invite.bonzaSession?.ends_at
                                        )}
                                    </p>
                                </MenuHeading>
                                <MenuItem>
                                    <button
                                        className={`
                                            flex w-full items-center rounded-lg
                                            py-1.5 pl-5 pr-3

                                            data-[focus]:bg-bonza-dark
                                        `}
                                        onClick={() =>
                                            sessionCallback(invite.id, true)
                                        }
                                    >
                                        {"Accept"}
                                    </button>
                                </MenuItem>
                                <MenuItem>
                                    <button
                                        className={`
                                            flex w-full items-center rounded-lg
                                            py-1.5 pl-5 pr-3

                                            data-[focus]:bg-bonza-dark
                                        `}
                                        onClick={() =>
                                            sessionCallback(invite.id, false)
                                        }
                                    >
                                        {"Reject"}
                                    </button>
                                </MenuItem>
                            </MenuSection>
                        ))}
                    </>
                )}
                {/*<MenuItem>
                    <button
                        className={`
                            mt-2 flex w-full items-center rounded-lg px-3 py-1.5

                            data-[focus]:bg-bonza-dark
                        `}
                        href="#"
                    >
                        {"View all"}
                    </button>
                </MenuItem>*/}
            </MenuItems>
        </Menu>
    )
}
